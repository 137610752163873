// Use this to write your custom SCSS
@import "user-variables";

// General style
body {
  font-family: Manrope, sans-serif;
}
.page {
  background-color: $default-page-bkg;
}

// layout
.display-none {
  display: none !important;
}

.display-inline-block {
  display: inline-block !important;
}

.hide-lt-sm {
  @media screen and (max-width: $sm-threshold) {
    display: none;
  }
}
.hide-lt-md {
  @media screen and (max-width: $md-threshold) {
    display: none;
  }
}
.hide-gt-md {
  @media screen and (min-width: $md-threshold) {
    display: none;
  }
}

.height-initial {
  height: initial !important;
}

.width-initial {
  width: initial !important;
}

.w-90 {
  width:90% !important;
}

.w-80 {
  width:80% !important;
}
.w-md-80 {
  @media screen and (min-width: $md-threshold) {
    width:80% !important;
  }
}
.w-sm-80 {
  @media screen and (min-width: $sm-threshold) {
    width:80% !important;
  }
}

.w-70 {
  width:70% !important;
}

.w-60 {
  width:60% !important;
}
.w-lg-60 {
  @media screen and (min-width: $lg-threshold) {
    width:60% !important;
  }
}
.w-md-60 {
  @media screen and (min-width: $md-threshold) {
    width:60% !important;
  }
}

.w-md-50 {
  @media screen and (min-width: $md-threshold) {
    width:50% !important;
  }
}

.w-20 {
  width:20% !important;
}
.w-md-20 {
  @media screen and (min-width: $md-threshold) {
    width:20% !important;
  }
}

.h-80 {
  height: 80%;
}
.h-70 {
  height: 70%;
}
.h-60 {
  height: 60%;
}
.h-33 {
  height: 33.3%;
}
.h-20 {
  height: 20% !important;
}
.h-10 {
  height: 10% !important;
}

.pe-50px {
  padding-right: 50px !important;
}

// buttons
// primary pale
.btn-primary-custom {
  color: $white;
  background-color: $primary;
  border-color: $primary !important;
}
.btn-primary-custom:hover {
  color: $white;
  background-color: $primary-light;
  border-color: $primary-light !important;
}
.btn-primary-custom:active {
  color: $primary !important;
  background-color: $white !important;
  border-color: $white !important;
}
.btn-primary-custom:disabled {
  color: $disabled;
  background-color: $text-label;
  border-color: $disabled;
}
// primary pale
.btn-primary-pale {
  color: $default-text-black;
  background-color: $white;
  border-color: $white;
}
.btn-primary-pale:hover {
  color: $primary-light;
  background-color: $white;
  border-color: $white;
}
.btn-primary-pale:active {
  color: $white !important;
  background-color: $primary-light !important;
  border-color: $primary-light !important;
}
.btn-primary-pale:disabled {
  color: $disabled;
  background-color: $text-label;
  border-color: $disabled;
}
// priamry outlined
.btn-primary-outlined {
  color: $white;
  background-color: #00000000; // transparent
  border-color: $white;
}
.btn-primary-outlined:hover {
  color: $primary-light !important;
  background-color: #00000000; // transparent
  border-color: $primary-light;
}
.btn-primary-outlined:active {
  color: $primary !important;
  background-color: #00000000 !important; // transparent
  border-color: $primary !important;
}
.btn-primary-outlined:disabled {
  color: $disabled;
  background-color: $text-label;
  border-color: $disabled;
}

// text colors
.text-black-opline {
  color: $default-text-black;
}
.text-primary-light-opline {
  color: $primary-light !important;
}
.text-label-opline {
  color: $text-label !important;
}
.text-subpage-opline {
  color: $text-subpage !important;
}

// text font
.fw-800 {
  font-weight: 800 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-400 {
  font-weight: 400 !important;
}

// font size
.fs-xs {
  font-size: .65rem;
}

// border colors
.border-black-opline {
  border-color: $default-text-black !important;
}
.border-primary-light-opline {
  border-color: $primary-light !important;
}

// COMMON ITEMS
.opline-banner-separator {
  height: 1vh;
  background: linear-gradient(to left, $secondary, $primary-light);
}

.form-input-error {
  padding-left: 1rem;
  height: 1.2rem;
  font-size: .75rem;
  margin-bottom: .25rem;
  color: $warning;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loading-dots {
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 5px;
  overflow: visible;
}

.input-box {
  position: relative;
}

.input-box .prefix {
  position:absolute;
  //left:5px;
  top: 0;
  bottom: 1.2rem;
  height: fit-content;
  margin: auto 0;
  padding: 0 5px;
  border-right: 1px solid $primary-light;
}

.input-box input {
  padding-left: 2.4rem !important;
}
.input-box label {
  padding-left: 3.4rem !important;
}

// Loading spinner classes
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $primary-light;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary-light transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// shimmer
.shimmering-element {
  background: linear-gradient(-45deg, $default-page-bkg 40%, $primary-light 50%, $default-page-bkg 60%);
  background-size: 300%;
  background-position-x: 100%;
  animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
  to {
    background-position-x: 0%;
  }
}

// TOOLBAR
.other-button-alternative {
 // 767px < visible; >767px hidden
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

// HOME
.home-page {
  .hero-container {
    background: linear-gradient(to left, #355C95, #3F8670);
    min-height: 55vh;
    color: white;

    .hero-carousel-wrapper {
      width:83%;
    }

    .hero-subtitle {
      max-width:90%;
    }
  }

  .feature-icon {
    width: 7.3vw;
  }

  .registration-banner-container {
    height: 100vh;

    .registration-bkg-carousel-item {
      height: 100vh; // must be equal the container height
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .registration-dark-overlay {
      position:absolute;
      top: 0;
      bottom: 0;
      left:0;
      right: 0;
      z-index: 1;
      background-color: rgba(0,0,0,0.1);
    }

    .registration-top-text {
      position: absolute;
      bottom:20vh;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      padding-right: 1rem;
      padding-left: 7rem;

      @media screen and (max-width: 768px) {
        padding-left: 1rem;
      }
    }

    .registration-bottom-banner {
      display:block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      height: 20vh;
      background-color: #3F8670B3;
      padding-left: 7.5rem;

      @media screen and (max-width: 768px) {
        display: none;
      }

      #registration-banner-email-input {
        background-color: rgba(246,246,246,.5);
      }

      .registration-policy-checkbox-text {
        a {
          color: white;
          text-decoration: underline;
        }
      }
    }
  }
}

.star-icon {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  margin-right: 5px !important;
}

// REWARDS
.rewards-page {
  .rewards-top-banner-container {
    height: 70vh;
    background-image: url("../img/photos/rewards-top-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .rewards-dark-overlay {
      position:absolute;
      top: 0;
      bottom: 0;
      left:0;
      right: 0;
      z-index: 1;
      background-color: rgba(0,0,0,0.1);
    }
  }

  .rewards-bottom-banner-container {
    height: 70vh;
    background-image: url("../img/photos/rewards-bottom-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .rewards-dark-overlay {
      position:absolute;
      top: 0;
      bottom: 0;
      left:0;
      right: 0;
      z-index: 1;
      background-color: rgba(0,0,0,0.1);
    }
  }
}
.reward-card {
  min-height: 25vh;
}

// REWARD DETAIL
.reward-detail-page {
  .reward-detail-top-banner-container {
    height: 32vh;
    background-image: url("../img/photos/reward-detail-top-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .reward-detail-dark-overlay {
      position:absolute;
      top: 0;
      bottom: 0;
      left:0;
      right: 0;
      z-index: 1;
      background-color: rgba(0,0,0,0.1);
    }
  }
  .reward-detail-bottom-banner-container {
    height: 69vh;
    background-image: url("../img/photos/reward-detail-bottom-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .reward-detail-dark-overlay {
      position:absolute;
      top: 0;
      bottom: 0;
      left:0;
      right: 0;
      z-index: 1;
      background-color: rgba(0,0,0,0.1);
    }
  }
}

// LEGAL DOCUMENTS
.legal-document-page {
  .legal-document-top-banner-container {
    height: 32vh;
    background-image: url("../img/photos/legal-document-top-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

// FAQ
.faq-page {
  .faq-top-banner-container {
    height: 32vh;
    background-image: url("../img/photos/faq-top-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

// LOGIN
.login-page {
  .login-top-banner-container {
    height: 57vh;
    background-image: url("../img/photos/login-top-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .login-dark-overlay {
      position:absolute;
      top: 0;
      bottom: 0;
      left:0;
      right: 0;
      z-index: 1;
      background-color: rgba(0,0,0,0.1);
    }
  }
}

// REGISTER
.register-page {
  .register-top-banner-container {
    height: 57vh;
    background-image: url("../img/photos/register-top-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .register-dark-overlay {
      position:absolute;
      top: 0;
      bottom: 0;
      left:0;
      right: 0;
      z-index: 1;
      background-color: rgba(0,0,0,0.1);
    }
  }
}

// VERIFY
.verify-page {
  .verify-top-banner-container {
    height: 57vh;
    background-image: url("../img/photos/register-top-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .verify-dark-overlay {
      position:absolute;
      top: 0;
      bottom: 0;
      left:0;
      right: 0;
      z-index: 1;
      background-color: rgba(0,0,0,0.1);
    }
  }

  .lds-ring {
    margin-top:-12px;
  }
}

// USER
.user-page {
  .user-top-banner-container {
    height: 57vh;
    background-image: url("../img/photos/login-top-banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .user-dark-overlay {
      position:absolute;
      top: 0;
      bottom: 0;
      left:0;
      right: 0;
      z-index: 1;
      background-color: rgba(0,0,0,0.1);
    }
  }

  #user-info-loading-shimmer {
    min-height: 20vh;
    @media screen and (max-width: 768px) {
      min-height: 50vh;
    }
  }

  .user-info-box {
    min-height: 30vh;
    // on small screen enlarge
    @media screen and (max-width: $md-threshold) {
      min-height: 100vh;
    }
  }

  #user-prizes-loading-shimmer {
    min-height: 30vh;
    @media screen and (max-width: $md-threshold) {
      min-height: 40vh;
    }
  }

  #redeem-reward-image {
    max-height: 12vh;
    max-width: 16vw;
    @media screen and (max-width: $md-threshold) {
      max-height: 24vh;
      max-width: 32vw;
    }
    @media screen and (max-width: $sm-threshold) {
      max-height: 36vh;
      max-width: 48vw;
    }
  }

  .reward-card.disabled {
    opacity: .4;
  }
  .not-active-desc.disabled {
    opacity: .4;
  }

  .hover-reward-card:hover {
    border:6px solid $primary-light;
  }
}

